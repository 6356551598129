import React from "react"
import PropTypes from "prop-types"
import Email from "./email"
import Phone from "./phone"
import DefaultTeamImg from "../images/logo.png"

import { FaExternalLinkAlt } from "react-icons/fa"
import { GatsbyImage } from "gatsby-plugin-image"

const TeamDetail = ({ team, className }) => {
  const labelStyle = {
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "uppercase",
    marginBottom: 0,
    color: "#0d8a48",
  }

  const valueStyle = {
    fontWeight: 400,
    marginBottom: 0,
    color: "#444444",
  }

  const teamPropertyStyle = {
    marginRight: "2rem",
    marginBottom: "0.8rem",
  }

  const contactInfo =
    team.contactInfoGroup?.showContactInfo === "captain"
      ? {
          email: team.captain.email,
          phone: team.captain.phone,
        }
      : team.contactInfoGroup?.showContactInfo === "own"
      ? team.contactInfoGroup?.contactInfo
      : null

  return (
    <div className={`d-flex flex-column flex-md-row ${className}`}>
      <div className="text-center text-md-left">
        {team.image ? (
          <GatsbyImage
            className="mr-md-4 mb-3"
            image={team.image.asset.gatsbyImageData}
            alt={team.name}
          />
        ) : (
          <img
            className="mr-md-4 mb-3"
            src={DefaultTeamImg}
            alt={team.name}
            style={{ maxWidth: "180px" }}
          />
        )}
      </div>
      <div className="text-center text-md-left">
        <h3>{team.name}</h3>
        <div className={"d-flex flex-column"}>
          {team.league && (
            <div style={teamPropertyStyle}>
              <p style={labelStyle}>Liga</p>
              <p style={valueStyle}>
                {team.league}
                <br />
              </p>
              <div>
                {team.url && (
                  <a href={team.url} target="_blank" rel="noopener noreferrer">
                    Tabelle & Spielplan
                    <FaExternalLinkAlt className="ml-2" />
                  </a>
                )}
              </div>
            </div>
          )}
          {team.captain && (
            <div style={teamPropertyStyle}>
              <p style={labelStyle}>
                {team.category === "women"
                  ? "Mannschaftsführerin"
                  : "Mannschaftsführer"}
              </p>
              <p style={valueStyle}>{team.captain.name}</p>
            </div>
          )}
          {contactInfo && (
            <div style={teamPropertyStyle}>
              <p style={labelStyle}>Kontakt</p>
              {contactInfo.email && <Email email={contactInfo.email} />}
              {contactInfo.phone && <br />}
              {contactInfo.phone && <Phone phone={contactInfo.phone} />}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

TeamDetail.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  phone2: PropTypes.string,
  className: PropTypes.string,
}

export default TeamDetail
