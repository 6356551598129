import React, { useEffect, useRef } from "react"
import Link from "../components/link"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "react-bootstrap/Container"
import TeamDetail from "../components/team-detail"
import { globalHistory } from "@reach/router"
import { scrollToRef } from "../utils/dom-utils"
import Button from "react-bootstrap/Button"
import { FaExternalLinkAlt } from "react-icons/all"
import LinkBack from "../components/link-back"
import { graphql } from "gatsby"
import { groupBy, mapValues } from "lodash-es"

const MannschaftenPage = ({ location, data }) => {
  const juniorenRef = useRef(null)
  const damenRef = useRef(null)
  const herrenRef = useRef(null)

  const allTeams = data.allSanityTeam.nodes.filter(t => t.isVisible)
  const groupedTeams = groupBy(allTeams, t => t.category)
  const groupedAndSorted = mapValues(groupedTeams, teams =>
    teams.sort((a, b) => a.position - b.position)
  )

  const navigateToHash = hash => {
    if (!hash) return
    switch (hash) {
      case "#junioren":
        scrollToRef(juniorenRef)
        break
      case "#damen":
        scrollToRef(damenRef)
        break
      case "#herren":
        scrollToRef(herrenRef)
        break
      default:
        break
    }
  }

  setTimeout(() => navigateToHash(location.hash), 100)

  useEffect(() => {
    // whenever the route changes we change the tab depending on the hash of the url
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        const hash = globalHistory.location.hash
        navigateToHash(hash)
      }
    })
  })

  return (
    <Layout>
      <Seo title="Mannschaften" />
      <section
        id="banner-mannschaften"
        className="banner d-flex flex-column justify-content-end"
      >
        <Container
          className="text-center text-md-left"
          style={{ maxWidth: "900px" }}
        >
          <p>
            <LinkBack to="/" label={"Homepage"} />
          </p>
          <h1>Mannschaften</h1>
        </Container>
      </section>
      <Container
        className="text-center text-md-left"
        style={{ maxWidth: "900px" }}
      >
        <section>
          <div className="d-flex flex-column flex-md-row flex-wrap justify-content-md-around">
            <Button
              href="https://hamburg.liga.nu/cgi-bin/WebObjects/nuLigaTENDE.woa/wa/clubMeetings?club=17867"
              target="_blank"
              variant="primary"
              className="px-4 m-2 d-flex align-items-center justify-content-center"
            >
              Punktspieltermine & Ergebnisse
              <FaExternalLinkAlt className="ml-2" />
            </Button>
            <Button
              href="https://hamburg.liga.nu/cgi-bin/WebObjects/nuLigaTENDE.woa/wa/clubTeams?club=17867"
              target="_blank"
              variant="primary"
              className="px-4 m-2 d-flex align-items-center justify-content-center"
            >
              Gemeldete Mannschaften
              <FaExternalLinkAlt className="ml-2" />
            </Button>
            <Button
              href="https://www.hamburger-tennisverband.de/"
              target="_blank"
              variant="primary"
              className="px-4 m-2 d-flex align-items-center justify-content-center"
            >
              Spielberichteingabe beim HTV
              <FaExternalLinkAlt className="ml-2" />
            </Button>
          </div>
          <p className="text-center">
            Mehr Infos auf{" "}
            <a href="https://www.hamburger-tennisverband.de">
              www.hamburger-tennisverband.de
            </a>
          </p>
        </section>
        <section>
          <p>
            Unsere Mannschaften freuen sich immer über neue Teammates! Fragen
            Sie einfach bei unserem{" "}
            <Link to="/training#trainer">Trainerteam</Link> oder den
            Mannschaftsführern direkt an.
          </p>
          <h2 className="mb-3" ref={juniorenRef}>
            Junioren
          </h2>
          Die Junioren haben im Winter nur eine Mannschaft gemeldet.
          {groupedAndSorted["kids"]?.map(team => (
            <TeamDetail key={team.name} team={team} className="mb-5" />
          ))}
        </section>
        <section>
          <h2 className="mb-3" ref={damenRef}>
            Damen
          </h2>
          {groupedAndSorted["women"]?.map(team => (
            <TeamDetail key={team.name} team={team} className="mb-5" />
          ))}
        </section>
        <section>
          <h2 className="mb-3" ref={herrenRef}>
            Herren
          </h2>
          {groupedAndSorted["men"]?.map(team => (
            <TeamDetail key={team.name} team={team} className="mb-5" />
          ))}
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityTeam {
      nodes {
        contactInfoGroup {
          showContactInfo
          contactInfo {
            email
            phone
          }
        }
        captain {
          name
          email
          phone
        }
        league
        name
        url
        category
        isVisible
        image {
          asset {
            gatsbyImageData(width: 180, placeholder: BLURRED)
          }
        }
        position
      }
    }
  }
`
export default MannschaftenPage
